import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/services/login.service';
import { Router, ActivatedRoute, convertToParamMap } from '@angular/router';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { UtilityService } from 'src/services/utility.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  username = '';
  password = '';
  password2 = '';
  remember = false;

  resetPassword = false;
  setPassword = false;
  forgotPassword = false;
  resetGuid: string;

  emailValidator = new FormControl('', [Validators.required, Validators.email]);
  newPasswordFormControl = new FormControl('', [Validators.required, Validators.minLength(8), createPasswordStrengthValidator()]);
  confNewPasswordFormControl = new FormControl('', [Validators.required, Validators.minLength(8), createPasswordStrengthValidator()]);

  constructor(public loginService: LoginService, private route: ActivatedRoute, private router: Router, private us: UtilityService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const paramMap = convertToParamMap(params);
      if (paramMap.has('mail'))
        {this.username = paramMap.get('mail');}
      if (paramMap.has('guid'))
        {this.resetGuid = paramMap.get('guid');}

      this.resetPassword = paramMap.has('reset');
      this.setPassword = paramMap.has('set');
      this.forgotPassword = paramMap.has('forgot');
      if (this.forgotPassword && this.resetPassword) {this.resetPassword = !this.resetPassword;}
    });
  }

  loginKeyDown(event) {
    console.log('key pressed');
    if (event.key === 'Enter') {
      this.login();
    }
  }

  login() {
    if (!this.emailValidator.valid) {this.us.toastMessage('please enter a valid email address');}
    else {this.loginService.validateGDPR(this.username, this.password, this.remember);}

  }

  resetKeyDown(event) {
    if (event.key === 'Enter')
      {this.reset();}
  }

  forgotKeyDown(event) {
    if (event.key === 'Enter')
      {this.sendForgotMail();}
  }

  reset() {
    if (this.newPasswordFormControl.invalid || this.confNewPasswordFormControl.invalid) {
      this.us.toastMessage('Please enter a valid password (at least 8 characters long,'+
        ' min. 1 uppercase, min. 1 lowercase, min. 1 number and min. 1 special character)!');
    } else if (this.password !== this.password2) {
      this.us.toastMessage('Passwords are not matching!');
    } else {
      this.loginService.reset(this.username, this.password, this.resetGuid).subscribe({
        next: (resp) => { if (resp) { this.resetPassword = false; this.forgotPassword = false; this.setPassword = false; } }
      });
    }
  }

  sendForgotMail() {
    if (!this.emailValidator.valid) {this.us.toastMessage('Please enter a valid email address!');}
    else {this.loginService.sendForgotMail(this.username);}
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createPasswordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]+/.test(value);

    const hasLowerCase = /[a-z]+/.test(value);

    const hasNumeric = /[0-9]+/.test(value);

    const hasSpecialCharacter = /[^a-zA-Z0-9]/.test(value);

    const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialCharacter;

    return !passwordValid ? {passwordStrength:true}: null;
  };
}
