/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/member-ordering */
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable,throwError} from 'rxjs';
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private headers: HttpHeaders;
  private url: string;

  private dataParameter: QueryParams;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    //this.url = "http://localhost:8099/TenantPortal";
    //this.url = "https://s02.pke-ee.at:8100/TenantPortal";
    this.url = environment.apiUrl + "tenantportal";
  }

  public getUrl() {
    return this.url;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error.error) {console.error(`Backend returned code ${error.status}, error was: `, error.error.error);}
    if (error.status === 0)
      // A client-side or network error occurred.
      {console.error('An error occurred:', error.error);}
    return throwError(() => new Error(error.error.msg !== "" ? error.error.msg : "An error occurred. Please try again later"));
  }

  public loadGDPR(username: string, password: string): Observable<any> {
    this.headers = this.headers.set("Authorization","Basic " + btoa(username + ":" + password));
    return this.http.get(this.url + "/load-gdpr", { headers: this.headers }).pipe(catchError(this.handleError));
  }

  setGDPR(value: boolean) {
    return this.http.post(this.url + "/set-gdpr",{ value }, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  public loadProjects(username: string, password: string): Observable<any> {
    this.headers = this.headers.set("Authorization","Basic " + btoa(username + ":" + password));
    return this.http.get(this.url + "/projects", { headers: this.headers }).pipe(catchError(this.handleError));
  }

  public loadProjectInfo(pguid: string): Observable<any> {
    return this.http.get(this.url + "/project/" + pguid, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  public loadTenantNames(pguids: string): Observable<any> {
    return this.http.get(this.url + "/tenant-names/" + pguids, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  public loadRooms(pguid: string): Observable<any> {
    return this.http.get(this.url + "/rooms/" + pguid, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  public setDataParams(pguid: string, from: number, until: number, timemode: number) {
    this.dataParameter = new QueryParams(pguid, from, until, timemode);
  }

  // username, password, timemode, locations, pguid, from, until
  public dataCall(room: string): Observable<object> {
    let header = this.headers;
    header = header.append("x-room", room);
    return this.http.get(this.url + "/data/" + this.dataParameter.pguid + "/" + this.dataParameter.from + "-" +
      this.dataParameter.until, {headers: header, params:
        {timemode: this.dataParameter.timemode,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone}}).pipe(catchError(this.handleError));
  }

  public setPasswordCall(username: string, password: string, resetGuid: string): Observable<any> {
    const base64Encode = btoa(username + ":" + password);

    const header = new HttpHeaders().set("Authorization", "Basic " + base64Encode);
    return this.http.post(this.url + "/setPassword", { resetGuid }, { headers: header }).pipe(catchError(this.handleError));
  }

  public sendPasswordForgotMail(email: string): Observable<any> {
    return this.http.post(this.url + "/forgotPassword", { email },
      { headers: this.headers }).pipe(catchError(this.handleError));
  }

  saveDataCall(db: string, collection: string, data: any, pguid: string = "", name: string = ""): Observable<any> {
    return this.http.post(this.url + "/saveJSONData", { db, collection, data, pguid, name },
      { headers: this.headers }).pipe(catchError(this.handleError));
  }

  loadDataCall(db: string, collection: string, pguid: string = "", name: string = ""): Observable<any> {
    return this.http.get(this.url + "/loadJSONData/" + db + "/" + collection + "/" + name +  "/" + pguid,
      { headers: this.headers }).pipe(catchError(this.handleError));
  }

  sendTestReport(pguid: string, report: any, lang: string): Observable<any> {
    return this.http.post(this.url + "/sendTestReport",{ pguid, report, language: lang },
      { headers: this.headers }).pipe(catchError(this.handleError));
  }

  checkPDF() {
    return this.http.get(this.url + '/getPDFName/eeTenantPortalDocu', { headers: this.headers });
  }
  openPdfInNewTab(fileid: string) {
    return this.http.get(this.url + '/getPDF/' + fileid, { headers: this.headers });
  }
  donwloadPDFDoku(fileid: string) {
    return this.http.get(this.url + '/getPDF/' + fileid, { headers: this.headers });
  }

  getPDFWithGUID(pguid: string): Observable<any> {
    //
    return this.http.post(this.url + '/getPDFWithGUID', { pguid }, { headers: this.headers });
  }

}

class QueryParams {

  public constructor(pguid, from, until, timemode) {
    this.pguid = pguid;
    this.from = from;
    this.until = until;
    this.timemode = timemode;
  }

  public pguid: string;
  public from: number;
  public until: number;
  public timemode: number;
}
