import { Injectable } from '@angular/core';
import {BehaviorSubject, first, Subject} from 'rxjs';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { UtilityService } from './utility.service';
import {ModalController} from '@ionic/angular';
import {GdprModalComponent} from '../app/login/gdpr/gdpr-modal.component';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  acceptedGDPR = true;

  private userData = new BehaviorSubject({user: '', pw: ''});
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public user$ = this.userData.asObservable();

  constructor(private storage: StorageService, private api: ApiService, private us: UtilityService, private modalCtrl: ModalController) {
  }

  //this method tries to log in with stored credentials
  public async storedCredentials() {
    console.log('using stored credentials');
    const user = await this.storage.get('username');
    const password = await this.storage.get('password');
    if (user != null && password != null)
      {this.login(user, password);}
  }


  // this method tries to log in at the remote with the provided username and password
  // if username and password were correct the logged-in user can be retrieved through the user$ observable
  public login(username: string, password: string, remember: boolean = false) {
    console.log('trying to log in...');
    this.api.loadProjects(username, password).subscribe({
      next: (resp) => {
        if (resp?.ok) {

          this.api.loadTenantNames(resp.data.join(';')).subscribe({
            next: (names) => {if (names?.ok) {this.us.projectIds = names.data;}},
            error: (err) => this.us.toastMessage(err)
          });

          this.api.loadProjectInfo(resp.data[0]).subscribe({
            next: (resp2) => {
              if (resp2) {
                this.api.loadRooms(resp.data[0]).subscribe({
                  next: (resp3) => {
                    if (resp3) {
                      const d = resp2;
                      d.pguid = resp.data[0];
                      d.tenantRooms = resp3;

                      this.us.projects.set(resp.data[0], d);

                      if (remember) {
                        this.storage.set('username', username);
                        this.storage.set('password', password);
                      }

                      this.us.projectGuids.next(resp.data);
                      this.userData.next({user: username, pw: password});
                    }
                  }, error: (err) => this.us.toastMessage(err)
                });
              }
            }, error: (err) => this.us.toastMessage(err)
          });

        }
      }, error: (err) => this.us.toastMessage(err)
    });
  }

  public reset(username: string, password: string, guid: string) {
    const ret = new Subject<boolean>();
    this.api.setPasswordCall(username, password, guid).subscribe({next: (resp) => {
        if (resp?.ok) {
          this.us.toastMessage('Successfully set new password!');
          ret.next(true);
        }
      }, error: (err) => this.us.toastMessage(err)});
    return ret.pipe(first());
  }

  public sendForgotMail(mail: string) {
    this.api.sendPasswordForgotMail(mail).subscribe({next: (resp) => {
        if (resp?.ok) {this.us.toastMessage(resp.msg);}
      }, error: (err) => this.us.toastMessage(err)});
  }

  public logout() {
    this.userData.next({user: '', pw: ''});
    this.storage.remove('username');
    this.storage.remove('password');
  }

  public async validateGDPR(username: string, password: string, remember: boolean = false) {
    this.api.loadGDPR(username, password).subscribe({next:  async (respGDPR) => {
        if (respGDPR?.ok && respGDPR.accepted) {this.login(username, password, remember);}
        else {
          const modal = await this.modalCtrl.create({
            component: GdprModalComponent,
          });
          modal.present();

          const data = await modal.onWillDismiss();

          if (data.data) {
            this.api.setGDPR(true).subscribe(
              () => {
                this.login(username, password, remember);
                this.acceptedGDPR = true;
              },
              (error) => this.us.toastMessage(error)
            );
          }
          else {this.acceptedGDPR = false;}
        }
      }, error: (err) => this.us.toastMessage(err)});

  }
}
