import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { deAT as de_date } from 'date-fns/locale';
import { addDays, addMinutes, addMonths, differenceInDays, endOfMonth, format, startOfDay, startOfMonth, startOfWeek,
  startOfYear} from 'date-fns';

export enum TIMEMODE {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DAY = 0,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WEEK = -10,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  MONTH = -20,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  YEAR = -30
}

export class Period {
  name: string;
  timemode: TIMEMODE;

  constructor(name: string, timemode: TIMEMODE) {
    this.name = name;
    this.timemode = timemode;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  static timeMode: TIMEMODE;
  public selectedProjSbj: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public premium: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public projectGuids: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public projects: Map<string, any> = new Map<string, any>();
  public projectIds: Map<string, string> = new Map<string, string>();

  constructor(private toast: ToastController) { }

  static getXLabels(startUnix: number, endUnix: number, timeMode: TIMEMODE): string[] {
    this.timeMode = timeMode;
    return this.setUpTimeScale(startUnix, timeMode);
  }

  private static setUpTimeScale(startDate: number, timeMode: TIMEMODE): Array<any> {
    const labels = new Array<string>();
    let formatString: string;
    switch (timeMode) { // declare labels for y axis
      case TIMEMODE.DAY:
        const time = startOfDay(Date.now());
        formatString = 'p';
        for (let i = 0; i < 96; i++)
        {labels.push(format(addMinutes(time, 15 * i), formatString, {locale: de_date}));}
        break;
      case TIMEMODE.WEEK:
        const weekday = startOfWeek(Date.now(), {locale: de_date});
        formatString = 'EEEEEE';
        for (let i = 0; i < 7; i++)
        {labels.push(format(addDays(weekday, i), formatString, {locale: de_date}));}
        break;
      case TIMEMODE.MONTH:
        formatString = 'dd';
        const dateMonth = startDate;
        let firstOfMonth = startOfMonth(dateMonth);
        const lastOfMonth = endOfMonth(dateMonth);
        while(differenceInDays(firstOfMonth, lastOfMonth) < 0) {
          labels.push(format(firstOfMonth, formatString, {locale: de_date}));
          firstOfMonth = addDays(firstOfMonth, 1);
        }
        labels.push(format(firstOfMonth, formatString, {locale: de_date}));
        break;
      case TIMEMODE.YEAR:
        const dateYear = startOfYear(Date.now());
        formatString = 'LLL';
        for (let i = 0; i < 12; i++)
        {labels.push(format(addMonths(dateYear, i), formatString, {locale: de_date}));}
        break;
    }
    return labels;
  }

  async toastMessage(msg: string) {
    this.toast.create({
      message: msg,
      duration: 3000
    }).then((t) => t.present());
  }

}
