import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/services/login.service';
import { StorageService } from 'src/services/storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  user: string;

  constructor(private loginService: LoginService) {

  }

  ngOnInit() {
    this.loginService.user$.subscribe(res => this.user = res.user);
    this.loginService.storedCredentials();
  }

}
