import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./userguide/userguide.module').then( m => m.UserguidePageModule)
  },
  {
    path: '',
    redirectTo: 'menu/dashboard',
    pathMatch: 'full'
  },  {
    path: 'help-page',
    loadChildren: () => import('./help-page/help-page.module').then( m => m.HelpPagePageModule)
  },
  {
    path: 'document-managment',
    loadChildren: () => import('./document-managment/document-managment.module').then( m => m.DocumentManagmentPageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
